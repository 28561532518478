<template>
  <div class="center-1200">
    <div class="flex-column screen">
      <div class="auto-margin shadow container" style="font-size: 1.4em">
        <h1 style="text-align: center">Thank you!</h1>
        <p class="vertical-spacing-top-half text-center">We will reach out about your car as soon as we can!</p>
      </div>
    </div>
  </div>
</template>

<script>
import { acceptMarketingMessage } from "../api/Marketing.js";

export default {
  name: "AcceptRemarketingOpportunity",
  mounted: function () {
    acceptMarketingMessage(this.$route.params.planId, this.$route.params.formId, this.$route.params.messageId);
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/base.scss";

.container {
  padding: $PADDING;
}

.screen {
  height: 100vh;
}
</style>
